<template>
	<div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md mb-3">
							<label><i class="far fa-user color-theme font-11 me-1"></i> Nome/CPF/RG</label>
                     <input type="text" class="form-control" v-model="pesquisa.valor" maxlength="200" v-focus @keyup.enter="buscarContas" placeholder="Busque por nome da conta, CPF ou RG" />
                  </div>
						<div class="col-md-4 col-xl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarContas"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal()">
										<i class="far fa-plus font-13 me-1"></i> Adicionar
									</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>

						<conta v-for="(usuario, index) in pesquisa.retorno.resultado" :key="index" :usuario="usuario" :index="index" @info="abrirInfo($event)" />
					</div>
				</div>
			</div>
      </div>
		
      <!-- modalAddConta -->
      <div class="modal fade" id="modalAddConta" tabindex="-1" aria-labelledby="modalAddContaLabel" aria-hidden="true">
			<div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content h-100">
					<div class="modal-header">
						<h5 class="modal-title" id="modalAddContaLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} conta {{ modal.usuario.id == null ? '' : ('('+ modal.usuario.nomeCompleto +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-usuario-tab" data-bs-toggle="pill" data-bs-target="#pills-usuario" type="button" role="tab" aria-controls="pills-usuario" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-permissoes-tab" data-bs-toggle="pill" data-bs-target="#pills-permissoes" type="button" role="tab" aria-controls="pills-permissoes" aria-selected="false">
											<i class="far fa-box font-12"></i><span class="ms-1 d-none d-sm-inline"> Pacotes</span>
										</button>
									</li>
								</ul>
							</div>
						</div>
						
						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-usuario" role="tabpanel" aria-labelledby="pills-usuario-tab">
								<div class="accordion" id="accordionExample">
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingOne">
											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
												<i class="fal fa-home"></i> Informações básicas
											</button>
										</h2>
										<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-user color-theme font-12 me-1"></i> Nome *</label>
														<input type="text" class="form-control" v-model="modal.usuario.nomeCompleto" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email *</label>
														<input type="text" class="form-control" v-model="modal.usuario.email" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-11 me-1"></i> CPF *</label>
														<input type="text" class="form-control" v-model="modal.usuario.cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-11 me-1"></i> RG</label>
														<input type="text" class="form-control" v-model="modal.usuario.rg" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data nascimento</label>
														<input type="text" class="form-control" v-model="modal.usuario.dataNascimento" v-mask="'##/##/####'" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo empresa *</label>
														<v-select name="Desktop" :options="['Lucro real', 'Simples nacional']" v-model="modal.usuario.tipoEmpresa" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modal.usuario.tipoEmpresa">
															<option v-for="(option, index) in ['Lucro real', 'Simples nacional']" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-key color-theme font-11 me-1"></i> Senha *</label>
														<input type="password" class="form-control" v-model="modal.usuario.senha" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-key color-theme font-11 me-1"></i> Confirmar senha *</label>
														<input type="password" class="form-control" v-model="confirmSenha" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingTwo">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												<i class="fal fa-map-marker-alt me-3"></i>Endereço
											</button>
										</h2>
										<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
														<div class="input-group">
															<input type="text" class="form-control" v-model="modal.usuario.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
															<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
																<i class="fas fa-search"></i>
															</a>
														</div>
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
														<input type="text" class="form-control" v-model="modal.usuario.endereco.rua" maxlength="200" />
													</div>
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.usuario.endereco.numero" maxlength="200" />
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
														<input type="text" class="form-control" v-model="modal.usuario.endereco.bairro" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
														<input type="text" class="form-control" v-model="modal.usuario.endereco.cidade" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
														<input type="text" class="form-control" v-model="modal.usuario.endereco.estado" maxlength="200" />
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
														<input type="text" class="form-control" v-model="modal.usuario.endereco.codMunicipio" maxlength="200" />
													</div>
													<div class="col-sm-8">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
														<input type="text" class="form-control" v-model="modal.usuario.endereco.complemento" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingThree">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												<i class="fal fa-phone-alt"></i> Telefone
											</button>
										</h2>
										<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-3 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
														<input type="text" class="form-control" v-model="modal.usuario.telefone.ddd" v-mask="['(##)', '(###)']" />
													</div>
													<div class="col-sm-5 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.usuario.telefone.telefone" v-mask="['####-####', '#####-####']" />
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
														<v-select name="Desktop" :options="['Fixo', 'Celular']" v-model="modal.usuario.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modal.usuario.telefone.tipo">
															<option v-for="(option, index) in ['Fixo', 'Celular']" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-12">
														<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
														<input type="text" class="form-control" v-model="modal.usuario.telefone.nomeContato" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-permissoes" role="tabpanel" aria-labelledby="pills-permissoes-tab">
								<div class="row">
									<div class="col-lg-12 pe-lg-0">
										<div class="card mb-2">
											<div class="card-body p-12">
												<div class="row">
													<div class="col-12 mb-3 d-flex justify-content-between">
														<h6 class="mb-0 mt-1 font-15"><i class="far fa-box color-theme font-13 me-2"></i>Pacotes</h6>
														<h6 class="mb-0">Valor total: {{valorRecomendado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</h6>
													</div>
													<div class="cards-150 cards-sm-175 cards-lg-225 cards-xxl-250" v-if="modal.pacotes.length != 0">
														<pacote v-for="(pacote, index) in modal.pacotes" :key="index" :index="index" :pacote="pacote" :tela="'CRIAR_PACOTE'" @contratar="adicionarPacote($event)"/>
													</div>
													<div class="col-12 my-5 text-center" v-else>
														<i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
														<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum pacote encontrado</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarContas">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import conta from '@/components/contas/Conta.vue'
import pacote from '@/components/contas/Pacote.vue'

export default {
	name: 'Contas',
	data: function () {
		return {
			pesquisa: {'valor': '', 'retorno': {'nome': null, 'resultado': []}},
			modal: {
				'usuario': {
					'id': null, 'idConta': null, 'email': null, 'senha': null, 'nomeCompleto': null, 'tipoEmpresa': null,'dataNascimento': null, 'senhaBarra': null, 'avatar': null, 'valor': null,
					'valorUtilizado': null, 'lojaConvenio': null, 'desabilitado0': false, 'convenioHabilitado': false, 'tentativasLogin': 0, 'dataCriacao': null,
					'lojas': [], 'modulos': [],'roles': [], 'tokenSessao': null, 'ultimaSolicitacao': null, 'numeroLojaConvenio': null, 'nomeLojaConvenio': null,
					'rg': null, 'cpf': null, 'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'},
					'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
				},
				'pacotes': [],
			},
			valorRecomendado: 0,
			valor1: 0,
			valor2: 0,
			confirmSenha: null
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
			this.$store.dispatch('getCountUsuarios').then((data) => this.pesquisa.totalUsuarios = data)
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		conta, pacote
	},
	methods: {
		abrirModal : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.modal = {
				'usuario': {
					'id': null, 'idConta': null, 'email': null, 'tipoEmpresa': null, 'senha': null, 'nomeCompleto': null,
					'dataNascimento': null, 'codigoSupervisor': null, 'codigoConvenio': null, 'avatar': null, 'valor': null,
					'valorUtilizado': null, 'lojaConvenio': null, 'desabilitado': null, 'convenioHabilitado': null,
					'tentativasLogin': null, 'dataCriacao': null, 'lojas': [], 'modulos': [],
					'roles': [], 'tokenSessao': null, 'ultimaSolicitacao': null, 'numeroLojaConvenio': null,
					'nomeLojaConvenio': null, 'cpf': null, 'rg': null, 'isMasterUsuario': true,
					'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'},
					'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
				},
				'pacotes': []
			}
			this.confirmSenha = null

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getNewConta',
				params: {
					id: null
				}

			}).then(response => {
				this.modal = {
					'usuario': {
						'id': response.data.usuario.id,
						'idConta': response.data.usuario.idConta,
						'email': response.data.usuario.email,
						'senha': response.data.usuario.senha,
						'nomeCompleto': response.data.usuario.nomeCompleto,
						'dataNascimento': response.data.usuario.dataNascimento == null ? null : String(response.data.usuario.dataNascimento.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.usuario.dataNascimento.monthValue).padStart(2, '0') +'/'+ response.data.usuario.dataNascimento.year, 
						'codigoSupervisor': response.data.usuario.codigoSupervisor,
						'codigoConvenio': response.data.usuario.codigoConvenio,
						'avatar': response.data.usuario.avatar,
						'valor': response.data.usuario.valor, 
						'valorUtilizado': response.data.usuario.valorUtilizado, 
						'lojaConvenio': response.data.usuario.lojaConvenio,
						'desabilitado': response.data.usuario.desabilitado,
						'convenioHabilitado': response.data.usuario.convenioHabilitado,
						'tentativasLogin': response.data.usuario.tentativasLogin,
						'endereco': response.data.usuario.endereco,
						'dataCriacao': response.data.usuario.dataCriacao,
						'telefone': response.data.usuario.telefone,
						'lojas': JSON.parse(JSON.stringify(response.data.usuario.lojas)),
						'modulos': JSON.parse(JSON.stringify(response.data.usuario.modulos)),
						'roles': JSON.parse(JSON.stringify(response.data.usuario.roles)),
						'tokenSessao': response.data.usuario.tokenSessao,
						'ultimaSolicitacao': response.data.usuario.ultimaSolicitacao,
						'numeroLojaConvenio': response.data.usuario.numeroLojaConvenio,
						'nomeLojaConvenio': response.data.usuario.nomeLojaConvenio,
						'cpf': response.data.usuario.cpf,
						'rg': response.data.usuario.rg,
						'isMasterUsuario': true
					}, 'pacotes': response.data.pacotes
				}

				for (let i = 0; i < this.modal.pacotes.length; i++) {
					this.modal.pacotes[i].qtdSelecionado = 0
				}

				$('#modalAddConta').modal('show')

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarContas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.retorno = {'loja': null, 'nome': null, 'resultado': []}

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/searchContas',
				params: {
					valor: this.pesquisa.valor,
				}

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		toggleModulo : function (idModulo) {
         this.modal.modulos.forEach(modulo => {
            if (modulo.id == idModulo) {
               modulo.habilitado = !modulo.habilitado

               modulo.roles.forEach(role => {
                  role.habilitado = modulo.habilitado
               });
            }
         });
      },
		buscarCEP : function () {
         if (String(this.modal.usuario.endereco.cep).trim().length < 8) {
            return;
         }

         this.$store.dispatch('buscarCEP', this.modal.usuario.endereco.cep).then((data) => {
				this.modal.usuario.endereco.rua = data.logradouro
				this.modal.usuario.endereco.complemento = data.complemento
				this.modal.usuario.endereco.bairro = data.bairro
				this.modal.usuario.endereco.cidade = data.localidade
				this.modal.usuario.endereco.estado = data.uf
				this.modal.usuario.endereco.codMunicipio = data.ibge
			})
      },
		salvarContas : function () {
			if (this.modal.usuario.nomeCompleto == null || String(this.modal.usuario.nomeCompleto).trim().length == 0 ||
				this.modal.usuario.email == null || String(this.modal.usuario.email).trim().length == 0 || 
				this.modal.usuario.tipoEmpresa == null || String(this.modal.usuario.tipoEmpresa).trim().length == 0 ||
				this.modal.usuario.senha == null || String(this.modal.usuario.senha).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			if (this.modal.usuario.senha != this.confirmSenha) {

				this.$toast.fire({
					icon: 'error',
					title: 'As senhas devem ser iguais'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveNewConta',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalAddConta').modal('hide')
				
				// this.$store.dispatch('getCountUsuarios').then((data) => this.pesquisa.totalUsuarios = data)
				this.buscarContas()

				this.$toast.fire({
					icon: 'success',
					title: 'Conta salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Limite de usuários excedido!'
						});
					} else if (error.response.status == 409) {
						this.$toast.fire({
							icon: 'warning',
							title: 'Email ou CPF já foram cadastrados'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		adicionarPacote : function (pacote) {
			if (pacote.tipo == 'unico') {
				this.modal.pacotes[pacote.index].habilitado = !this.modal.pacotes[pacote.index].habilitado
				if (this.modal.pacotes[pacote.index].habilitado) {
					this.modal.pacotes[pacote.index].qtdSelecionado = 1
				} else {
					this.modal.pacotes[pacote.index].qtdSelecionado = 0
				}
			}

			if (pacote.tipo == 'multiplo') {
				if (pacote.count > 0) {
					this.modal.pacotes[pacote.index].habilitado = true
					this.modal.pacotes[pacote.index].qtdSelecionado = pacote.count
				} else if (pacote.count == 0) {
					this.modal.pacotes[pacote.index].habilitado = false
					this.modal.pacotes[pacote.index].qtdSelecionado = pacote.count
				}
			}

			this.valorRecomendado = 0
			this.calcularValores(pacote)
		},
		calcularValores() {
         for (var i = 0; i < this.modal.pacotes.length; i++) {
				if (this.modal.pacotes[i].habilitado) {
					this.valorRecomendado += this.modal.pacotes[i].valor * this.modal.pacotes[i].qtdSelecionado
				}
         }
      },
	},
	mounted() {
		this.$store.dispatch('getCountUsuarios').then((data) => this.pesquisa.totalUsuarios = data)
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>