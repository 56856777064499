<template>
   <div class="card mb-0" ref="pacote" :index="index" :class="pacote.habilitado ? 'borderSelected': ''">
      <div class="card-img overflow-hidden">
         <img :src="pacote.foto == null ? '' : pacote.foto" class="card-img-top" alt="icon" @error="imageError">
      </div>
      <div class="card-body text-center pt-12">
         <h5 class="limitador-2 color-theme">{{ pacote.nome }}</h5>
         <p class="align-text-center descricao d-none d-lg-block" v-if="pacote.descricao"><span class="limitador-3">{{ pacote.descricao }}</span></p>
         <p class="limitador-1 valor" v-if="tela == 'CRIAR_PACOTE'">R$ {{ parseFloat(pacote.valor).toFixed(2) }}</p>
         <div v-if="pacote.qtd == null && pacote.nome == 'Sistema'">
            <div title="Sempre habilitado por padrão">
               <button class="btn btn-white invert disabled"><i class="fal fa-check me-1"></i> Habilitado</button>
            </div>
         </div>
         <div v-else-if="pacote.qtd == null">
            <button class="btn btn-white invert" v-if="!pacote.habilitado" @click="contratar('unico')"><i class="fal fa-plus me-1"></i> Adicionar</button>
            <button class="btn btn-white invert" v-else @click="contratar('unico')"><i class="fal fa-times me-1"></i> Remover</button>
         </div>
         <div class="d-flex justify-content-center align-items-center" v-else>
            <button class="btn btn-white invert me-2" @click="atribuirCount(-1)" :class="count < 1 ? 'disabled' : ''"><i class="fal fa-minus"></i></button>
            <h5 class="color-theme valorDiv mb-0 mt-2">{{ count }}</h5>
            <button class="btn btn-white invert ms-2" @click="atribuirCount(1)"><i class="fal fa-plus"></i></button>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Pacote',
   props: ['pacote', 'index', 'tela'],
   data: function () {
		return {
			count: 0
		}
	},
   computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
   methods: {
      contratar : function (tipo) {
         this.$emit('contratar', { index: this.index, count: this.count, tipo: tipo })
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      atribuirCount : function (valor) {
         this.count = this.count + valor
         this.contratar('multiplo')
      },
      cancelar : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Cancelar pacote?',
            text: 'Será cobrado o valor proporcional ao tempo de uso na próxima fatura.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoes/cancelPacote',
                  params: {
                     id: this.pacote.id
                  }
               }).then(() => {
                  this.$refs.pacote.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Pacote cancelado!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   },
   mounted() {
      if (this.pacote.nome == 'Sistema') {
         this.contratar('unico');
      }
   }
}

</script>

<style scoped>

h5 {
   font-size: 14px;
   margin-bottom: 8px;
   font-weight: 500;
   line-height: 20px;
}

.card[index] img {
	aspect-ratio: 1/1;
	object-fit: cover;
   transition: all .3s ease-out 0s;
}

.card[index]:hover img {
   transform: scale(1.1) rotate(1deg);
}

.descricao {
   height: 60px;
   margin-bottom: 0;
   font-size: 13px;
   line-height: 20px;
}

.valor {
   font-size: 15px;
   font-weight: 500;
   margin-top: 10px;
   margin-bottom: 0;
}

button {
   margin-top: 10px;
   transition: all 0.5s;
   padding: 5px 10px;
   font-size: 12px;
}

button:hover {
   background-color: var(--color-theme);
   border-color: var(--color-theme);
   color: #fff;
}

.borderSelected {
   border: var(--color-theme) 2px solid !important;
}

</style>